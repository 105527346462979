import moment from "moment";
export const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
export const upperCaseRegex = /^(?=.*[A-Z])/;
export const lowerCaseRegex = /^(?=.*[a-z])/;
export const oneNumberRegex = /^(?=.*\d)/;
export const oneSymbolRegex = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;

// Default validation rules:
//     matchRegexp
//     isEmail
//     isEmpty
//     required
//     trim
//     isNumber
//     isFloat
//     isPositive
//     minNumber
//     maxNumber
//     minFloat
//     maxFloat
//     isString
//     minStringLength
//     maxStringLength
//     maxFileSize
//     allowedExtensions

export function customValidators(ValidatorForm, args = {}) {
  const { state } = args;

  ValidatorForm.addValidationRule("duplicatePhoneNumber", () => true);

  ValidatorForm.addValidationRule(
    "strongPassword",
    (value) => value?.match(strongPasswordRegex) || value === ""
  );
  // custom rule will have name 'isShort'
  ValidatorForm.addValidationRule("isShort", (value) => {
    if (value.length < 5) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'isNonAlphabetic'
  ValidatorForm.addValidationRule("isNonAlphabetic", (value) => {
    if (!/^[a-zA-Z ]*$/.test(value)) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'alphabetAndDash'
  ValidatorForm.addValidationRule("alphabetAndDash", (value) => {
    if (!/^[a-zA-Z -]*$/.test(value)) {
      return false;
    }
    return true;
  });

  ValidatorForm.addValidationRule("minValLength", (val) =>
    !val ? true : val.length < 8 ? false : true
  );

  // custom rule will have name 'isAlphaNumericSym'
  ValidatorForm.addValidationRule("isAlphaNumericSym", (value) => {
    if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(value)) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'isCountryCode'
  ValidatorForm.addValidationRule("isCountryCode", (value) => {
    if (!value) {
      return true;
    } else if (/^[+0-9]*$/.test(value)) {
      return true;
    }

    return false;
  });

  // custom rule will have name 'isStartWithPlus'
  ValidatorForm.addValidationRule("isStartWithPlus", (value) => {
    if (value && !String(value).startsWith("+")) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'isRequiredPhoneCode'
  ValidatorForm.addValidationRule("isRequiredPhoneCode", (value) => {
    if (!value && state.phone) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'isRequiredPhoneCodeAlt'
  ValidatorForm.addValidationRule("isRequiredPhoneCodeAlt", (value) => {
    if (!value && state.phone_alt) {
      return false;
    }
    return true;
  });

  // custom rule will have name 'isUnderAge'
  ValidatorForm.addValidationRule("isUnderAge", (value) => {
    const selectedDate = moment(value, "YYYY-MM-DD");
    const years = moment().diff(selectedDate, "years", true);

    if (years < 8) {
      return false;
    }
    return true;
  });
}
